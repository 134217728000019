import React from 'react';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import Layout from '../components/Layout';

import Card from '../components/Card';

const EventPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>Agenda</h2>
          <p>Les prochains événements</p>
        </div>
      </header>

      <div className="wrapper">
        <div className="inner">

          <section className="nav-event">
            <button className="small primary">Tous</button>
            <button className="small default">Les concerts</button>
            <button className="small primary">Les créations</button>
          </section>
          
          <h4>Septembre</h4>

          <section className="features">

            <Card type='agenda' path="/concert" title="Mickael Gat à la Belleviloise" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." date="01/11/2022 à 20h30" place="La bellevilloise"/>
            <Card type='agenda' path="/concert" title="Mickael Gat à la Belleviloise" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." date="01/11/2022 à 20h30" place="La bellevilloise"/>
            <Card type='agenda' path="/concert" title="Concert d'AKM à Bercy" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus." date="01/12/2023 à 22h30" place="Bercy"/>
            

          </section>
          <h4>Aout</h4>

          <section className="features">

            <Card type='agenda' path="/concert" title="Mickael Gat à la Belleviloise" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." date="01/11/2022 à 20h30" place="La bellevilloise"/>
            <Card type='agenda' path="/concert" title="Mickael Gat à la Belleviloise" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." date="01/11/2022 à 20h30" place="La bellevilloise"/>
            <Card type='agenda' path="/concert" title="Mickael Gat à la Belleviloise" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." date="01/11/2022 à 20h30" place="La bellevilloise"/>
            <Card type='agenda' path="/concert" title="Concert d'AKM à Bercy" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus." date="01/12/2023 à 22h30" place="Bercy"/>
            

          </section>
          <h4>Juin</h4>

          <section className="features">

            <Card type='agenda' path="/concert" title="Mickael Gat à la Belleviloise" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." date="01/11/2022 à 20h30" place="La bellevilloise"/>
            <Card type='agenda' path="/concert" title="Mickael Gat à la Belleviloise" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." date="01/11/2022 à 20h30" place="La bellevilloise"/>
            <Card type='agenda' path="/concert" title="Mickael Gat à la Belleviloise" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." date="01/11/2022 à 20h30" place="La bellevilloise"/>
            <Card type='agenda' path="/concert" title="Mickael Gat à la Belleviloise" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." date="01/11/2022 à 20h30" place="La bellevilloise"/>
            <Card type='agenda' path="/concert" title="Mickael Gat à la Belleviloise" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." date="01/11/2022 à 20h30" place="La bellevilloise"/>
            <Card type='agenda' path="/concert" title="Mickael Gat à la Belleviloise" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." date="01/11/2022 à 20h30" place="La bellevilloise"/>
            <Card type='agenda' path="/concert" title="Mickael Gat à la Belleviloise" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." date="01/11/2022 à 20h30" place="La bellevilloise"/>
            <Card type='agenda' path="/concert" title="Mickael Gat à la Belleviloise" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." date="01/11/2022 à 20h30" place="La bellevilloise"/>
            <Card type='agenda' path="/concert" title="Concert d'AKM à Bercy" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus." date="01/12/2023 à 22h30" place="Bercy"/>
            

          </section>
          <section className="footer">
            <button className="small">Voir les événements passés</button>
          </section>
        </div>
      </div>
    </section>
  </Layout>
);

export default EventPage;
